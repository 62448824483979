<template>
  <div id="cgu_extranet">
    <CguExtranetDe v-if="country === 'de'" />
    <CguExtranetFr v-else />
  </div>
</template>

<script>
import CguExtranetFr from "@/components/legal/extranet/CguExtranetFr.vue";
import CguExtranetDe from "@/components/legal/extranet/CguExtranetDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Extranet dans la bonne langue.
 */
export default {
  name: "CguExtranet",
  components: {
    CguExtranetFr,
    CguExtranetDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
